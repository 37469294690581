import React, { useEffect } from "react"

import { GatsbyPage } from "src/types"
import Layout from "src/layout"
import { Section } from "src/components/styled"
import { Content, Breadcrumbs, StepTitle, ShopTitle } from "src/components/shop"
import { Label } from "src/components/Form"
import { Link, navigate } from "gatsby"
import { useCart } from "src/components/cart"

const ConfirmationPage: GatsbyPage = ({ location }) => {
  const { reset } = useCart()

  useEffect(() => {
    if (location.state) {
      reset()
    } else {
      navigate("/", { replace: true })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout title="Confirmation" noindex>
      <ShopTitle />
      <Section>
        <Content>
          <Breadcrumbs active="confirmation" />
          <StepTitle>Votre commande est validée</StepTitle>
          <Label>Merci de votre confiance et à bientôt à l’atelier.</Label>
          <StepTitle>Et maintenant ?</StepTitle>
          <Label>
            Vous allez recevoir un e-mail de confirmation de votre inscription avec toutes les informations relatives
            aux ateliers.
            <br />
            <br />
            Si vous avez commandé des cartes cadeaux, vous allez recevoir une version numérique de la carte par e-mail
            automatiquement. Si vous avez pris l’option envoi par la poste de la version papier, vous la recevrez à
            l’adresse indiquée dans les prochains jours.
            <br />
            <br />
            <br />
            <Link to="/">Retour à l’accueil</Link>
          </Label>
        </Content>
      </Section>
    </Layout>
  )
}

export default ConfirmationPage
